import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    keywords,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seoInfo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    keywords: keywords || "",
  }

  return (
    <Helmet title={seoInfo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seoInfo.description} />
      <meta name="image" content={seoInfo.image} />

      {seoInfo.url && <meta property="og:url" content={seoInfo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seoInfo.title && <meta property="og:title" content={seoInfo.title} />}

      {seoInfo.description && (
        <meta property="og:description" content={seoInfo.description} />
      )}

      {seoInfo.keywords && (
        <meta name="og:keywords" content={seoInfo.keywords} />
      )}

      {seoInfo.image && <meta property="og:image" content={seoInfo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seoInfo.title && <meta name="twitter:title" content={seoInfo.title} />}

      {seoInfo.description && (
        <meta name="twitter:description" content={seoInfo.description} />
      )}

      {seoInfo.image && <meta name="twitter:image" content={seoInfo.image} />}
    </Helmet>
  )
}

export default Seo

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        keywords
        siteUrl
        defaultImage: image
      }
    }
  }
`
