import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__main__grid main-container">
          <div className="footer__main__grid__contact">
            <span>CONTACT US</span>
            <div className="horizontal-line" />

            <a href="tel:+351 231 410 890">Phone: (+351) 231 410 890</a>
            <div className="horizontal-line" />
            <a href="mailto:lino@uc-biotech.pt">Email: lino@uc-biotech.pt</a>
            <div className="horizontal-line" />
            <Link to="/contacts">Map & Directions</Link>
            <div className="horizontal-line" />
          </div>
          <div className="footer__main__grid__address">
            <span>ADDRESS</span>
            <div className="horizontal-line" />
            <p>
              Parque Tecnológico de Cantanhede, Núcleo 04, Lote 3 <br />
              3060-197 Cantanhede, PORTUGAL
            </p>
            <br />
            <a href="/the-group/#job-opportunities">
              <button>JOB OPPORTUNITIES</button>
            </a>
          </div>
          <div className="footer__main__grid__funders">
            <span>FUNDERS & COLLABORATORS</span>
            <div className="horizontal-line" />
            <p>
              This project has received funding from the European Union's
              Horizon 2020 research and innovation programme under grant
              agreement No 669088.
            </p>
            <div>
              <div>
                <StaticImage
                  draggable="false"
                  src="../../images/flag_yellow_low.jpg"
                  alt="Euro Flag"
                  layout="constrained"
                  width={120}
                />
              </div>
              <div>
                <StaticImage
                  draggable="false"
                  src="../../images/collabators.png"
                  alt="Biomaterials SCT LAB"
                  layout="constrained"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer__secondary">
        <div className="footer__secondary__grid main-container">
          <span className="footer__secondary__grid__copyright">
            Copyrights © 2021 ATG. All Rights Reserved.
          </span>
          {/* <span className="footer__secondary__grid__terms">
            Terms & Conditions
          </span> */}
          <span className="footer__secondary__grid__design">
            Design:{" "}
            <a
              href="https://www.fba.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FBA.
            </a>{" "}
            &{" "}
            <a
              href="https://www.divisa.pt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Divisa.
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}
