import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import ATG from "../../images/Advanced Therapies Group.svg"
import AnimateHeight from "react-animate-height"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

console.log(
  "Hello Sherlock. 🕵️‍♂️ \nCongratulations, you found our secret badge!🎖 \nNow that you became an inspector,\nyou can find more at: www.divisa.pt 🍿\nWe hope you enjoy your quest. Bye! 👋"
)

export default function Navbar() {
  const [height, setHeight] = useState(60)

  const navbar = useRef(null)

  height === 60 ? enableBodyScroll(navbar) : disableBodyScroll(navbar)

  useEffect(() => {
    clearAllBodyScrollLocks()
  }, [])

  return (
    <AnimateHeight
      onMouseEnter={() => {
        setHeight("auto")
      }}
      onMouseLeave={() => {
        setHeight(60)
      }}
      className="navbar"
      height={height}
      ref={navbar}
    >
      <div className="main-container">
        <div className="navbar__grid">
          <h1 className="navbar__grid__logo">
            <Link to="/">
              <img draggable="false" src={ATG} alt="Advanced Therapies Group" />
            </Link>
          </h1>
          <button
            className="navbar__grid__menu"
            onClick={() => {
              setHeight(height === 60 ? "auto" : 60)
            }}
          >
            {height === 60 ? "Menu" : "Close"}
          </button>
          <nav className="navbar__grid__nav nav-desktop">
            <Link
              className="nav-arrow"
              activeClassName="nav-arrow--active"
              to="/the-group"
            >
              The Group
            </Link>
            <Link
              className="nav-arrow"
              activeClassName="nav-arrow--active"
              to="/research"
            >
              Research
            </Link>
            <Link
              className="nav-arrow"
              activeClassName="nav-arrow--active"
              to="/publications"
            >
              Publications
            </Link>
            <Link className="nav-arrow" to="/news">
              News & Events
            </Link>
            <Link
              className="nav-arrow"
              activeClassName="nav-arrow--active"
              to="/resources"
            >
              Resources
            </Link>
          </nav>

          <p className="navbar__grid__description">
            The research group aims at generating fundamental and translational
            knowledge in the intersection of biomaterials with stem cells.
          </p>

          <nav className="navbar__grid__nav nav-mobile">
            <ul>
              <Link
                className="nav-arrow"
                activeClassName="nav-arrow--active"
                to="/the-group"
              >
                The Group
              </Link>
              <li>
                <a href="/the-group/#overview">Overview</a>
              </li>
              <li>
                <a href="/the-group/#team">Team</a>
              </li>
              {/* <li>
                <a href="/the-group/#collaborators">Collaborators</a>
              </li> */}
              <li>
                <a href="/the-group/#job-opportunities">Job Opportunities</a>
              </li>
              <br />
            </ul>

            <ul>
              <Link
                className="nav-arrow"
                activeClassName="nav-arrow--active"
                to="/research"
              >
                Research
              </Link>
              <li>
                <a href="/research/#areas-of-investigation">
                  Areas of Investigation
                </a>
              </li>
              <li>
                <a href="/research/#projects">Projects</a>
              </li>
              <li>
                <a href="/research/#significance">Significance</a>
              </li>
              <li>
                <a href="/research/#approaches">Approaches</a>
              </li>
              <li>
                <a href="/research/#contributions">Contributions</a>
              </li>
              <br />
            </ul>

            <ul>
              <Link
                className="nav-arrow"
                activeClassName="nav-arrow--active"
                to="/publications"
              >
                Publications
              </Link>
              <li>
                <a href="/publications#books">Books</a>
              </li>
              <li>
                <a href="/publications#book-chapters">Book Chapters</a>
              </li>
              <li>
                <a href="/publications#conference-papers">Conference Papers</a>
              </li>
              <li>
                <a href="/publications#journal-articles">Journal Articles</a>
              </li>
              <li>
                <a href="/publications#patents">Patents</a>
              </li>
              <br />
            </ul>

            <ul>
              <Link className="nav-arrow" to="/news">
                News & Events
              </Link>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/internal-seminars">Internal Seminars</Link>
              </li>
              <li>
                <Link to="/public-outreach">Public Outreach</Link>
              </li>
              <br />
            </ul>

            <ul>
              <Link
                className="nav-arrow"
                activeClassName="nav-arrow--active"
                to="/resources"
              >
                Resources
              </Link>
              <li>
                <a href="/resources/#facilities-and-equipment">
                  Facilities and Equipment
                </a>
              </li>
              <li>
                <a href="/resources/#high-throughput-screening-facility">
                  High-throughput screening facility
                </a>
              </li>
            </ul>
          </nav>

          <nav className="navbar__grid__nav nav-desktop">
            <ul>
              <li>
                <a href="/the-group/#overview">Overview</a>
              </li>
              <li>
                <a href="/the-group/#team">Team</a>
              </li>
              {/* <li>
                <a href="/the-group/#collaborators">Collaborators</a>
              </li> */}
              <li>
                <a href="/the-group/#job-opportunities">Job Opportunities</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/research/#areas-of-investigation">
                  Areas of Investigation
                </a>
              </li>
              <li>
                <a href="/research/#projects">Projects</a>
              </li>
              <li>
                <a href="/research/#significance">Significance</a>
              </li>
              <li>
                <a href="/research/#approaches">Approaches</a>
              </li>
              <li>
                <a href="/research/#contributions">Contributions</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/publications#books">Books</a>
              </li>
              <li>
                <a href="/publications#book-chapters">Book Chapters</a>
              </li>
              <li>
                <a href="/publications#conference-papers">Conference Papers</a>
              </li>
              <li>
                <a href="/publications#journal-articles">Journal Articles</a>
              </li>
              <li>
                <a href="/publications#patents">Patents</a>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/internal-seminars">Internal Seminars</Link>
              </li>
              <li>
                <Link to="/public-outreach">Public Outreach</Link>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/resources/#facilities-and-equipment">
                  Facilities and Equipment
                </a>
              </li>
              <li>
                <a href="/resources/#high-throughput-screening-facility">
                  High-throughput screening facility
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </AnimateHeight>
  )
}
